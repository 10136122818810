import certificate from '../assets/png/certificate.jpg';
import cert from '../assets/png/certificate2.jpg'

export const achievementData = {
    bio : "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn. - Alvin Toffler",
    achievements : [
        {
            id : 1,
            title : 'Full Stack Web Developer',
            details : 'Full Stack Web Development Course',
            date : 'Jan, 2024',
            field : 'Coding',
            image : certificate
        },
        {
            id : 1,
            title : 'Python Programming',
            details : 'Python Programming Internship',
            date : 'Jul, 2024',
            field : 'Coding',
            image : cert
        },

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/