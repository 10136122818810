import resume from '../assets/pdf/resume.pdf'
import logo from '../assets/png/buki.jpg'
import profileimg from '../assets/png/birukti.jpg'

export const headerData = {
    name: 'Biruktawit Daniel',
    title: "Jr. Full Stack Web Developer",
    desciption:"Life may be a meandering path but don't worry about it, rather enjoy it. It is about the journey, not the destination so explore all that comes your way, learn and grow from it, for this journey only happens once.",
    image: logo,
    resumePdf: 'https://drive.google.com/file/d/1EYnQUF68URlULE85nz-7cQ_SOwbSKNzZ/view?usp=drive_link'
}
