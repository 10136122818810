export const educationData = [
    {
        id: 1,
        institution: 'Evangadi Tech',
        course: 'Full Stack Web Development',
        startYear: '2023',
        endYear: '2024'
    },
    {
        id: 2,
        institution: 'Addis Ababa Tegbareid Polytechnic College',
        course: 'Automotive in the field of vehicle servicing',
        startYear: '2022',
        endYear: '2023'
    },
    {
        id: 3,
        institution: 'Università Politecnica delle Marche',
        course: 'Digital Economics and Business',
        startYear: '2023',
        endYear: 'Present'
    },
]