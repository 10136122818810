import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'

import yaredgarage from '../assets/png/yared-garage.jpg'
import amazon  from '../assets/png/amazon.jpeg'
import netflix from '../assets/png/netflix.jpeg'
import apple from '../assets/png/apple-boots.jpeg'
import appplereact from '../assets/png/apple.jpeg'
import evangadiforum from '../assets/png/evangadiforum.jpg'

export const projectsData = [
    {
        id: 1,
        projectName: 'Yared Garage',
        projectDesc: 'This project is a comprehensive software solution meticulously crafted to streamline and elevate the day-to-day workflow of Yared Garage. The application is designed to simplify key processes such as customer intake, appointment management, and task tracking, with the ultimate goal of enhancing operational efficiency and maximizing customer satisfaction.',
        tags: ['React', 'MySql' ,'Node', 'Express'],
        code: 'https://github.com/danibirukti/Yared-Garage-Main-App',
        demo: 'https://www.yaredgarage.com',
        image: yaredgarage
    },
    {
        id: 1,
        projectName: 'Evangadi Forum',
        projectDesc: 'Built with React.js and Express, our platform serves as a hub for Evangadi suggestions and student queries. Students can ask questions and offer suggestions, fostering community engagement and support',
        tags: ['React', 'MySql', 'Express'],
        code: 'https://github.com/danibirukti/evangadi-forum/tree/main',
        demo: 'https://evangadiforum-birukti.netlify.app/',
        image: evangadiforum
    },
    {
        id: 2,
        projectName: 'Amazon Clone',
        projectDesc: 'This project is an Amazon.com e-commerce website clone. The Amazon Clone project replicates the functionality of the popular e-commerce platform using Node.js and Firebase, offering users a streamlined shopping experience with key Amazon features.',
        tags: ['React', 'Firbase','Material UI'],
        code: 'https://github.com/danibirukti/amazon-clone',
        demo: 'https://amazon-clone-birukti.netlify.app/',
        image: amazon
    },
    
    {
        id: 3,
        projectName: 'Apple Clone',
        projectDesc: 'This project is a Bootstrap-based clone of Apples website, featuring a sleek and responsive design inspired by Apples aesthetic. Experience the essence of Apples design language and user experience with this meticulously crafted clone.',
        tags: ['HTML', 'CSS', 'Bootstrap'],
        code: 'https://github.com/danibirukti/apple-website-clone',
        demo: 'https://birukti-apple-clone-bootstrap.netlify.app/',
        image: apple
    },
    {
        id: 4,
        projectName: 'Apple clone with React',
        projectDesc: 'This is a replica of Apples website made using React. It mimics the look and feel of Apples site, but its built with React for smoother performance and interactivity.',
        tags: ['EJS', 'Express', 'MongoDB', 'Node.js'],
        code: 'https://github.com/danibirukti/Apple-using-React',
        demo: 'https://apple-react-by-birukti.netlify.app/',
        image: appplereact
    },
    
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/